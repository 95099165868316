.inputgrouplogo {
    width: 22px;
}

span#basic-addon1 {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

.quantity::-webkit-inner-spin-button,
.quantity::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

@media only screen and (min-width: 320px) and (max-width: 420px) {
    .container.bg-body {
        width: 92%;
    }
}


.admonitionIcon_Ibzs {
    display: inline-block;
    margin-right: 0.4em;
    vertical-align: middle;
    width: 2.5rem;
    color: red;
}

.admonitionIcon_Ibzs>svg {
    fill: #055160;
}

.alertlocal {
    padding: 2rem !important;
}

.font-weight-bold.alert-heading.h4 {
    font-weight: bold;
}

.alertdesc {
    line-height: 1.8rem;
}

@media only screen and (min-width: 320px) and (max-width: 420px) {
    .container.alertbody {
        width: 92%;
    }
}

/* .formdesign {
    box-shadow: 0 0 25px hsla(0, 0%, 0%, 0.14);
    background-color: white;
    border-radius: 0.5rem;
    padding: 5rem;
} */