.headermg {
    margin-right: 0px !important;
    margin-left: 0px !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
}

.headerstick {
    position: sticky;
    top: 0;
}

.mainLogo {
    cursor: pointer;
}

.invalid.invalid-feedback {
    display: block;
}

@media only screen and (max-width: 990px) and (min-width: 768px) {
    .headsubtitle {
        font-size: 0.9rem;
    }
}

@media only screen and (min-width: 320px) and (max-width: 420px) {
    .mainLogo {
        max-width: 90%;
    }

    .headsubtitle {
        text-align: center;
        font-size: 0.8rem;
    }

    .headtitle {
        text-align: center;
    }

    .headerstick {
        position: static;
    }
}