.headerlogo {
    width: 281.25px;
    height: 69.75px;
}

.headerbylink {
    text-decoration: none;
    color: #0F55B2;
    font-size: small;
}

.navbar {
    background-color: #F3F3FE;
}

.homebanner {
    background-color: #F3F3FE;
    height: 350px;
}