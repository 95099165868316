.text-Box {
    flex-direction: column;
    display: flex;
    height: 90vh;
    align-items: center;
    justify-content: center;
}

.mheight {
    height: 100vh;
}