.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 10s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

/* Custom CSS */

.maintain {
  min-height: 100%;
  height: 800px;
}

.bg {
  background-image: url(./Images/bg.png);
  background-repeat: repeat;
  background-position: left;
  background-size: cover;
}

.notic {
  align-items: center;
  vertical-align: middle;
  margin: 0 60px;
}

/* @media only screen and (max-width: 1920px) and (min-width: 1240px) {
  .notic {
    height: 90vh;
  }
} */

@media only screen and (min-width: 320px) and (max-width: 420px) {
  .notic {
    margin: 0 20px;
    text-align: center;
  }
}

.min-w-0 {
  min-width: 0;
}

.min-w-full {
  min-width: 100%;
}

.nugstart {
  padding: 10px 20px;
  background-color: #F0F0F0;
  align-items: center;
  margin-right: 0 !important;
}

.nug {
  padding-left: 10px;
  padding-top: 4px;
  padding-bottom: 4px;
  font-size: 0.8rem;
  font-family: 'Poppins', sans-serif;
}

.subbannertitle {
  font-size: 0.8rem;
  font-family: 'Poppins', sans-serif;
}

.nugctext {
  font-size: 0.8rem;
  font-family: 'Poppins', sans-serif;
}

.nugtop {
  align-items: center;
  padding-top: 10px;
}

.complaintlogo {
  width: auto;
  margin-bottom: 1rem;
  align-items: center;
}

.hypernug {
  color: black;
  font-style: normal;
  text-decoration: none;
  font-family: 'Poppins', sans-serif;
}

.agreementicn {
  width: 42px !important;
  padding-top: 40px;
}

.ptitle {
  font-weight: bold;
  margin: 16px 0;
  font-size: 20px;
  text-align: center;
}

.ctitle {
  font-weight: bold;
  margin: 16px 0;
  font-size: 25px;
  color: #034DA2;
  text-align: center;
}

.pcontact {
  font-size: 13px;
}

.tnc {
  text-align: center !important;
}

.link {
  text-decoration: none;
  color: #0F55B2;
}

.known {
  background-color: #0f55b21f;
  border-radius: 4px;
  padding: 6px 9px;
}

@media only screen and (min-width: 320px) and (max-width: 420px) {
  .known {
    margin-top: 1rem;
  }
}

@media only screen and (min-width: 1024px) {
  .agreebtn {
    margin-top: 20px;
    width: 70%;
  }
  .phonebtn {
    width: 100%;
  }
}

@media only screen and (max-width: 600px) {
  .agreebtn {
    margin-top: 20px;
    width: 80%;
  }
  .phonebtn {
    width: 100%;
  }
}

@media only screen and (min-width: 320px) and (max-width: 420px) {
  .agreebtn {
    margin-top: 20px;
    width: 100%;
    font-size: 0.8rem;
  }

  .phonebtn {
    width: 100%;
  }

  .ctitle {
    font-size: 1.3rem;
  }

  .ptitle {
    font-size: 1.2rem;
  }
}

.optionaltxt {
  color: gray;
  font-weight: 300;
}

.mandatorytxt {
  color: red;
}

.progress {
  margin: 20px 0px;
}

.photonotic {
  align-items: center;
  text-align: center;
  font-size: small;
}

.center {
  text-align: center;
  display: block;
  justify-content: center;
  align-items: center;
  margin: auto;
  width: 100%;
}

.introtxt {
  padding-bottom: 69px;
  padding-right: 20px;
  padding-left: 20px;
}

.btn-primary {
  background-color: #034DA2 !important;
  /* background-color: #97B3D7 !important; */
}

#btn-agree-primary {
  background-color: #034DA2 !important;
}

.p-4fornext {
  padding: 1.5rem !important;
  padding-bottom: 2.5rem !important;
}

.astrodivider {
  width: 400px;
  max-width: 100%;
  position: relative;
}

.astrodividermask {
  overflow: hidden;
  height: 20px;
}

.astrodividermask:after {
  content: '';
  display: block;
  margin: -25px auto 0;
  width: 100%;
  height: 25px;
  border-radius: 125px / 12px;
  box-shadow: 0 0 8px #034DA2;
}

.astrodivider span {
  width: 50px;
  height: 50px;
  position: absolute;
  bottom: 100%;
  margin-bottom: -25px;
  left: 50%;
  margin-left: -25px;
  border-radius: 100%;
  box-shadow: 0 2px 4px #034DA2;
  background: #fff;
}

.astrodivider i {
  position: absolute;
  top: 4px;
  bottom: 4px;
  left: 4px;
  right: 4px;
  border-radius: 100%;
  border: 1px dashed #034DA2;
  text-align: center;
  line-height: 40px;
  font-style: normal;
  color: #034DA2;
}

.divider {
  position: relative;
  margin: 2rem 0;
  height: 1px;
}

.div-transparent:before {
  content: "";
  position: absolute;
  top: 0;
  left: 10%;
  width: 80%;
  height: 1px;
  background-image: linear-gradient(to right, transparent, rgb(3, 77, 162), transparent);
}

.div-arrow-down:after {
  content: "";
  position: absolute;
  z-index: 1;
  top: -7px;
  left: calc(50% - 7px);
  width: 14px;
  height: 14px;
  transform: rotate(45deg);
  background-color: white;
  border-bottom: 1px solid rgb(3, 77, 162);
  border-right: 1px solid rgb(3, 77, 162);
}

.contactBox svg {
  width: 2em;
  height: 2em;
  /* color: #034DA2; */
  color: #5569BB;
  margin: 10px;
}

.agreebtn svg {
  width: 1.2em;
  height: 1.2em;
  color: #fff;
  margin: 10px;
}

.agreebtn label {
  width: 80%;
}

.footer p {
  font-family: 'Poppins', sans-serif;
}

.headerlogosize {
  width: 40%;
  padding: 1.4rem;
  height: auto;
}

@media only screen and (min-width: 320px) and (max-width: 420px) {
  .headerlogosize {
    padding: 1.2rem;
    width: 90%;
    height: auto;
  }
}

.link {
  cursor: pointer;
  font-weight: bold;
}

.min-h-screen {
  min-height: 85vh;
}

.animate {
  animation: animationFrom 2s;
}

@keyframes animationFrom {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.pointer {
  cursor: pointer;
}

/* .btn {
  border-color: #97B3D7;
} */

.btn-outline-primary {
  --bs-btn-color: #6a80a0;
  --bs-btn-border-color: #97B3D7;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #084298;
  --bs-btn-hover-border-color: #084298;
  --bs-btn-focus-shadow-rgb: 13, 110, 253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #084298;
  --bs-btn-active-border-color: #0d6efd;
  --bs-btn-active-shadow: inset 0 3px 5pxrgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #0d6efd;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #0d6efd;
  --bs-gradient: none;
}

hr.scline {
  border-top: 1px dashed rgb(117, 104, 104);
  margin: 2rem 0rem;
}

.helplabel {
  color: #636464 !important;
}

.labelcenter {
  margin-left: -19px !important;
}

.btnsolve {
  position: fixed;
  bottom: 20px;
  right: 20px;
  font-size: 0.8rem;
  background: orange;
  color: white;
  cursor: pointer;
  border-radius: 10px;
  border: none;
  box-shadow: 0 5px 10px #ccc;
}

@media only screen and (min-width: 320px) and (max-width: 420px) {
  .btnsolve {
    position: fixed;
    bottom: 16px;
    right: 16px;
  }
}

marquee.news-content {
  background-color: #013691;
  color: white;
  font-weight: 600;
  margin: 0 !important;
  line-height: 2rem;
}